export const loadStyleSheet = async (stylePath: string | string[], callback?: () => void) => {
  const stylePathArray = Array.isArray(stylePath) ? stylePath : [stylePath]
  const head = document.head
  const promises = stylePathArray.map(
    path =>
      new Promise<void>((resolve, reject) => {
        const link = document.createElement('link')

        link.type = 'text/css'
        link.rel = 'stylesheet'
        link.href = path

        link.onload = () => {
          resolve()
        }

        link.onerror = () => {
          reject()
        }

        head.appendChild(link)
      }),
  )

  Promise.allSettled(promises).then(() => {
    if (callback) {
      callback()
    }
  })
}
